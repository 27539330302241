import isUUID from 'validator/lib/isUUID';

export const validateHemaIds = (hemaIdArr: string[]) => {
  let notValidUuid;
  let isValid = true;
  if (!hemaIdArr.length) {
    isValid = false;
  }

  hemaIdArr.forEach((id) => {
    if (!isUUID(id)) {
      isValid = false;
      notValidUuid = id;
    }
  });

  return { isValid, notValidUuid };
};

export const validateStatusEditForm = (selectedStatus: string | undefined, declineMessage: string) => {
  if (!selectedStatus) {
    return false;
  }
  if (selectedStatus === 'DECLINED') {
    return declineMessage !== '';
  }
  return true;
};
