import { FormControl, RadioGroup, FormControlLabel, Radio, TextField, Box } from '@mui/material';
import { useState } from 'react';
import { StatusType } from '../../services/gdpr.api';
import Status from '../Status/Status';

const StatusManagement = ({
  onStateChange,
  onDeclineMessage,
}: {
  onStateChange: (status: StatusType) => void;
  onDeclineMessage: (message: string) => void;
}) => {
  const [checkboxValue, setCheckboxValue] = useState('');
  const [declinedTextfield, setDeclinedTextfield] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const status = (event.target as HTMLInputElement).value;
    setCheckboxValue(status);
    onStateChange(status as StatusType);
    setDeclinedTextfield('');
  };
  return (
    <Box style={{ display: 'flex', flexDirection: 'column' }}>
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue=""
          name="radio-buttons-group"
          value={checkboxValue}
          onChange={handleChange}
        >
          <FormControlLabel value="IN_PROGRESS" control={<Radio />} label={<Status status="IN_PROGRESS" />} />
          <FormControlLabel value="COMPLETED" control={<Radio />} label={<Status status="COMPLETED" />} />
          <FormControlLabel value="DECLINED" control={<Radio />} label={<Status status="DECLINED" />} />
        </RadioGroup>
      </FormControl>
      {checkboxValue === 'DECLINED' && (
        <FormControl>
          <TextField
            value={declinedTextfield}
            type="text"
            placeholder="Reason for decline"
            variant="outlined"
            style={{ paddingTop: '1rem' }}
            onChange={async (event) => {
              const message = event.target.value;
              setDeclinedTextfield(message);
              onDeclineMessage(message);
            }}
            required
          />
        </FormControl>
      )}
    </Box>
  );
};

export default StatusManagement;
