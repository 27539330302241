import styled from '@emotion/styled';
import {
  HemaStatusDeclinedColor,
  HemaGreenColor,
  HemaStatusInProgressColor,
  HemaRedColor,
  HemaCreatedColor,
} from '../../assets/color/hema-colors';
import { StatusType } from '../../services/gdpr.api';

type Props = {
  status: StatusType;
};

const StatusStyle = styled('div')<{ statusType: StatusType }>(
  {
    display: 'flex',
    color: 'white',
    width: '100px',
    height: '2rem',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '3px',
    fontSize: '12px',
    fontWeight: '400',
  },
  (props) => ({
    background: getBackgroundColor(props.statusType),
  })
);

const getBackgroundColor = (status: string) => {
  switch (status) {
    case 'DECLINED':
      return HemaStatusDeclinedColor;
    case 'COMPLETED':
      return HemaGreenColor;
    case 'CREATED':
      return HemaCreatedColor;
    case 'FAILED':
      return HemaRedColor;
    default:
      return HemaStatusInProgressColor;
  }
};

export const statusTextMapper = (status: StatusType) => {
  return status.replace('_', ' ').toLowerCase();
};

const Status = ({ status }: Props) => {
  return <StatusStyle statusType={status}>{statusTextMapper(status)}</StatusStyle>;
};

export default Status;
