import axios from 'axios';

export type StatusType = 'COMPLETED' | 'IN_PROGRESS' | 'DECLINED';

export type RequestType = 'FORGET_ME';

export type ServiceRequest = {
  id: number;
  hemaId: string;
  serviceId: string;
  serviceName: string;
  serviceOwnerEmail: string;
  note: string;
  status: StatusType;
  canUpdate: boolean;
  updatedAt: string;
};

export type CustomerRequestResponseItem = {
  id: number;
  customerRequestId: number;
  hemaId: string;
  requestType: RequestType;
  requestId: string;
  created: string;
  status: StatusType;
  expirationDate: string;
  serviceRequests: ServiceRequest[];
};

export type CustomerRequestResponse = {
  size: number;
  items: CustomerRequestResponseItem[];
};

export type QueryParameters = {
  limit?: number;
  offset?: number;
  sort?: string;
  status?: string;
  filter?: string;
};

export const getCustomerRequests = async (
  params: QueryParameters,
  token?: string
): Promise<CustomerRequestResponse> => {
  const url = `/gdprcompliance/v1/requests`;
  const result = await axios.get(url, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return result.data;
};

export type ServiceRequestStatusType = {
  serviceRequestId: number;
  status: StatusType;
  note: string;
};

export const updateServiceRequestStatus = async ({
  serviceRequestStatuses,
  token,
}: {
  serviceRequestStatuses: ServiceRequestStatusType[];
  token: string;
}) => {
  const url = `/gdprcompliance/v1/service-requests/status`;

  const result = await axios.put(
    url,
    {
      input: serviceRequestStatuses,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return result.data;
};

export const createCustomerRequest = async ({
  body,
  token,
}: {
  body: { input: { hemaId: string }[] };
  token: string;
}) => {
  await axios.post(`/gdprcompliance/v1/forgetme-requests`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
