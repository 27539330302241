/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { CustomizedDialog } from '../../components/Modals/Modal';
import StatusManagement from '../../components/StatusManagement/StatusManagement';
import CreateNewRequestForm from '../../components/CreateNewRequestForm/CreateNewRequestForm';
import { CollapsibleTable } from '../../components/CollapsibleTable/CollapsibleTable';
import { BodyWrapper, Container, HeaderTitle, HeaderWrapper, SubTitle } from './CaseSearch.styles';
import {
  CustomerRequestResponseItem,
  getCustomerRequests,
  StatusType,
  updateServiceRequestStatus,
  createCustomerRequest,
} from '../../services/gdpr.api';
import { statusFormatter } from '../../components/CollapsibleTable/utils/formatter';
import FormFilter from '../../components/FormFilter/FormFilter';
import { validateStatusEditForm } from '../../utils/validators';
import { MSALAccount } from '../../App';

const LIMIT = 10;

const CaseSearch = ({
  toggleLoading,
  setSuccessMessage,
  accessToken,
  graphData,
  setInfoMessage,
  setErrorMessage,
}: {
  toggleLoading: Function;
  setSuccessMessage: Function;
  accessToken: string;
  graphData?: MSALAccount;
  setInfoMessage: Function;
  setErrorMessage: Function;
}) => {
  // Filters
  const [searchField, setSearchField] = useState('');
  const [selectedStatus, setSelectedStatus] = useState<StatusType>();
  const [declineMessage, setDeclineMessage] = useState<string>('');
  const [statusFilter, setStatusFilter] = useState<string[]>([]);

  // Table states
  const [rows, setRows] = useState([] as CustomerRequestResponseItem[]);
  const [totalRows, setTotalRows] = useState(0);
  const [openModalEditStatus, toggleStatusEditModal] = useState(false);
  const [paginationNumber, setPaginationNumber] = useState<number>(1);
  const [serviceIdsToEdit, setServiceIdsToEdit] = useState<number[]>([]);

  // create request state
  const [openModaCreateRequest, toggleCreateRequestsModal] = useState(false);
  const [hemaIdsForCreation, setHemaIdsForCreation] = useState<string[]>([]);
  const [isCreateRequestFormValid, setIsCreateRequestFormValid] = useState(false);

  const handleBasicErrors = (error: any) => {
    if (error?.message.includes('401')) {
      setErrorMessage(
        `${error?.message} (${error.response?.data?.message}). Please logout and login to obtain new authentication token! `
      );
    } else {
      setErrorMessage(`${error?.message} (${error.response?.data?.message}). Contact System Admin.`);
    }
  };

  const getRequests = async ({
    status,
    filter,
    offset,
    token,
  }: {
    status: string;
    filter?: string;
    token?: string;
    offset?: number;
  }) => {
    const offsetCalculation = (offset !== undefined ? offset : paginationNumber) * LIMIT;

    try {
      toggleLoading(true);
      const cases = await getCustomerRequests(
        {
          limit: LIMIT,
          offset: offsetCalculation,
          status,
          filter,
        },
        token || accessToken
      );
      setRows(cases.items);
      setTotalRows(Math.ceil(cases.size / LIMIT));
    } catch (error: any) {
      handleBasicErrors(error);
    } finally {
      toggleLoading(false);
      setSelectedStatus(undefined);
      setDeclineMessage('');
    }
  };

  useEffect(() => {
    if (accessToken) {
      getRequests({
        status: statusFormatter(statusFilter.join(',')),
        offset: 0,
        token: accessToken,
      });
    }
  }, [statusFilter, accessToken]);

  const handleFilterChange = async (value: string[]) => {
    setStatusFilter(value);
    setPaginationNumber(1);
    await getRequests({
      status: statusFormatter(value.join(',')),
      offset: 0,
    });
  };

  const handleFilterTextChange = async (filter: string) => {
    setSearchField(filter);
    setPaginationNumber(1);
    await getRequests({
      status: statusFormatter(statusFilter.join(',')),
      filter,
      offset: 0,
    });
  };

  const handleStatusEditFormSubmit = async () => {
    try {
      resetMessages();
      const serviceRequestStatuses = serviceIdsToEdit.map((service) => ({
        serviceRequestId: service,
        status: selectedStatus as StatusType,
        note: declineMessage,
      }));

      toggleLoading(true);

      await updateServiceRequestStatus({
        serviceRequestStatuses,
        token: accessToken,
      });
    } catch (error: any) {
      handleBasicErrors(error);
    } finally {
      toggleLoading(false);
      toggleStatusEditModal(false);
    }

    await getRequests({
      status: statusFormatter(statusFilter.join(',')),
      filter: searchField,
      offset: paginationNumber - 1,
    });
  };

  const handleCreateRequestsFormSubmit = async () => {
    try {
      resetMessages();
      toggleLoading(true);
      const input = hemaIdsForCreation.map((hemaId) => ({ hemaId }));
      await createCustomerRequest({
        body: {
          input,
        },
        token: accessToken,
      });
      setSuccessMessage('Successfully created request/s !');
    } catch (error: any) {
      if (error?.response.status === 401) {
        setErrorMessage(
          `${error?.message} (${error.response?.data?.message}). Please logout and login to obtain new authentication token! `
        );
      } else if (error?.response.status === 409) {
        const messages = JSON.parse(error.response?.data?.message);
        const hemaIds = messages.map((value: { hemaId: string }) => value.hemaId).join(', ');
        setInfoMessage(`These hemaIds already exist: ${hemaIds}`);
      } else if (error?.response.status === 500) {
        const messages = JSON.parse(error.response?.data?.message);
        const existingHemaIds = messages
          .map((value: { hemaId: string; errorMessage: string }) =>
            value.errorMessage === 'ALREADY_EXISTS' ? value.hemaId : undefined
          )
          .filter((value: string | undefined) => value !== undefined);

        if (existingHemaIds.length) {
          setInfoMessage(`These hemaIds already exist: ${existingHemaIds.join(', ')}`);
        }
        const failedHemaIds = messages
          .map((value: { hemaId: string; errorMessage: string }) =>
            value.errorMessage === 'FAILURE' ? value.hemaId : undefined
          )
          .filter((value: string | undefined) => value !== undefined);
        if (failedHemaIds.length) {
          setErrorMessage(`${error?.message} (Creation for these hemaIds have failed: ${failedHemaIds.join(', ')}).`);
        }
      }
    } finally {
      toggleLoading(false);
      toggleCreateRequestsModal(false);
      await getRequests({
        status: statusFormatter(statusFilter.join(',')),
        filter: searchField,
        offset: paginationNumber - 1,
      });
    }
  };

  const resetMessages = () => {
    setErrorMessage('');
    setInfoMessage('');
    setSuccessMessage('');
  };

  return (
    <Container data-testid="case-search">
      <HeaderWrapper>
        <HeaderTitle>my gdpr cases</HeaderTitle>
        <SubTitle>
          hi, {graphData?.givenName}. Here you can find all your GDPR cases that fall within your area of
          responsibility.
        </SubTitle>

        <FormFilter
          onFilterTextChange={handleFilterTextChange}
          onFilterStatusChange={handleFilterChange}
          toggleCreateRequestsModal={toggleCreateRequestsModal}
        />
      </HeaderWrapper>

      <BodyWrapper>
        <CollapsibleTable
          rows={rows}
          totalPageCount={totalRows}
          paginationNumber={paginationNumber}
          onPaginationChanged={async (number) => {
            setPaginationNumber(number);
            await getRequests({
              status: statusFormatter(statusFilter.join(',')),
              filter: searchField,
              offset: Math.ceil(number - 1),
            });
          }}
          onEditIconClick={(service) => {
            setServiceIdsToEdit([]);
            setServiceIdsToEdit([service.id]);
            toggleStatusEditModal(true);
          }}
          onBulkEditClick={(services: number[]) => {
            setServiceIdsToEdit(services);
            toggleStatusEditModal(true);
          }}
        />
      </BodyWrapper>
      {openModalEditStatus && (
        <CustomizedDialog
          modalTitle="change the status"
          formIsValid={validateStatusEditForm(selectedStatus, declineMessage)}
          onModalClose={() => {
            toggleStatusEditModal(false);
          }}
          onModalOpen={() => {
            toggleStatusEditModal(true);
          }}
          onModalAccept={handleStatusEditFormSubmit}
        >
          <StatusManagement
            onStateChange={async (status: StatusType) => {
              setSelectedStatus(status);
            }}
            onDeclineMessage={(message: string) => {
              setDeclineMessage(message);
            }}
          />
        </CustomizedDialog>
      )}
      {openModaCreateRequest && (
        <CustomizedDialog
          modalTitle="Create new requests"
          formIsValid={isCreateRequestFormValid}
          onModalClose={() => {
            toggleCreateRequestsModal(false);
            setHemaIdsForCreation([]);
          }}
          onModalOpen={() => {
            toggleCreateRequestsModal(true);
          }}
          onModalAccept={handleCreateRequestsFormSubmit}
        >
          <CreateNewRequestForm
            setIsCreateRequestFormValid={setIsCreateRequestFormValid}
            setHemaIdsForCreation={setHemaIdsForCreation}
            showErrorMessage={!isCreateRequestFormValid && hemaIdsForCreation.length > 0}
          />
        </CustomizedDialog>
      )}
    </Container>
  );
};

export default CaseSearch;
