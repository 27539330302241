export const HemaBlueDarkColor = '#33B6DC';

export const HemaStatusDeclinedColor = '#DC6A21';

export const HemaGreenColor = '#9AC122';

export const HemaRedColor = '#FF0000';

export const HemaStatusInProgressColor = '#1956CD';

export const HemaBeigeColor = '#F1EFEB';

export const HemaCreatedColor = '#D900FF';

export const HemaWhiteColor = '#FFFFFF';
