import { ReactNode } from 'react';
import styled from '@emotion/styled';
import Header from '../components/Header/Header';

const Container = styled.div`
  background-color: #f1efeb;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;
const HeaderGridWrapper = styled.div`
  width: 100%;
`;

const Layout = ({ children }: { children: ReactNode }) => {
  return (
    <Container data-testid="app-layout">
      <HeaderGridWrapper>
        <Header />
      </HeaderGridWrapper>
      {children}
    </Container>
  );
};

export default Layout;
