import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import Checkbox from '@mui/material/Checkbox';

import { Button } from '@mui/material';
import Status from '../Status/Status';
import { dateFormatter, typeFormatter } from './utils/formatter';
import { HemaBeigeColor, HemaBlueDarkColor, HemaWhiteColor } from '../../assets/color/hema-colors';
import { CustomerRequestResponseItem, ServiceRequest } from '../../services/gdpr.api';

export const Row = (props: {
  row: CustomerRequestResponseItem;
  isOddRow: boolean;
  onBulkEditClick?: (serviceIds: number[]) => void;
  onEditIconClick?: (affectedServiceRow: ServiceRequest) => void;
}) => {
  const { row, isOddRow, onEditIconClick, onBulkEditClick } = props;
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState<number[]>([]);
  const [selectAllCheck, setSelectAllCheck] = React.useState<string>('');

  const handleEditIconClick = (affectedServiceRow: ServiceRequest) => {
    if (onEditIconClick) {
      onEditIconClick(affectedServiceRow);
    }
  };

  const handleBulkEditClick = (affectedServiceRow: number[]) => {
    if (onBulkEditClick) {
      onBulkEditClick(affectedServiceRow);
    }
  };

  const lastBorderStyle = (index: number, services?: ServiceRequest[]) => {
    return {
      border: services && services.length - 1 === index ? 'none' : '',
    };
  };

  return (
    <React.Fragment>
      <TableRow
        sx={{ '& > *': { borderBottom: 'unset' } }}
        style={{ backgroundColor: isOddRow ? HemaWhiteColor : HemaBeigeColor }}
      >
        <TableCell style={{ width: 10 }}>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left">{row.requestId}</TableCell>
        <TableCell align="left">{row.hemaId}</TableCell>
        <TableCell align="left">{dateFormatter({ date: row.created })}</TableCell>
        <TableCell align="left">{typeFormatter(row.requestType)}</TableCell>
        <TableCell align="left">{dateFormatter({ date: row.expirationDate })}</TableCell>
        <TableCell align="left">
          <Status status={row.status} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: 10 }}>
                      <Checkbox
                        value={selectAllCheck}
                        disabled={!row.serviceRequests.some((serviceRequest) => serviceRequest.canUpdate)}
                        checked={selectAllCheck === 'all'}
                        indeterminate={selectAllCheck === 'intermediate'}
                        style={{
                          color: row.serviceRequests.some((serviceRequest) => serviceRequest.canUpdate)
                            ? HemaBlueDarkColor
                            : HemaBeigeColor,
                        }}
                        onChange={(event) => {
                          if (event.target.value === 'all') {
                            setSelectAllCheck('');
                            setSelected([]);
                            return;
                          }
                          if (event.target.value === '' || event.target.value === 'intermediate') {
                            setSelectAllCheck('all');
                            setSelected(
                              row.serviceRequests
                                ?.filter((s) => s.canUpdate)
                                .map((r) => {
                                  return r.id;
                                })
                            );
                          }
                        }}
                      ></Checkbox>
                    </TableCell>
                    <TableCell>services</TableCell>
                    <TableCell>service-id</TableCell>
                    <TableCell>responsible</TableCell>
                    <TableCell>status</TableCell>
                    <TableCell>last change</TableCell>
                    <TableCell>notes</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.serviceRequests?.map((serviceRow, index) => (
                    <TableRow key={serviceRow.serviceName}>
                      <TableCell
                        style={{
                          width: 10,
                          ...lastBorderStyle(index, row?.serviceRequests),
                        }}
                      >
                        <Checkbox
                          style={{
                            color: serviceRow.canUpdate ? HemaBlueDarkColor : HemaBeigeColor,
                          }}
                          disabled={!serviceRow.canUpdate}
                          onChange={() => {
                            setSelected([]);
                            let newState = [];
                            if (selectAllCheck === 'all' || selected.includes(serviceRow.id)) {
                              newState = selected.filter((s) => s !== serviceRow.id);
                              setSelected(newState);
                            } else {
                              newState = [...selected, serviceRow.id];
                              setSelected(newState);
                            }

                            if (newState.length === 0) {
                              setSelectAllCheck('');
                              return;
                            }

                            if (row && row.serviceRequests && newState.length !== row.serviceRequests.length) {
                              setSelectAllCheck('intermediate');
                            }

                            if (row && row.serviceRequests && newState.length === row.serviceRequests.length) {
                              setSelectAllCheck('all');
                            }
                          }}
                          checked={selected.indexOf(serviceRow.id) > -1 && serviceRow.canUpdate}
                        ></Checkbox>
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          ...lastBorderStyle(index, row?.serviceRequests),
                        }}
                      >
                        {serviceRow.serviceName}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          ...lastBorderStyle(index, row?.serviceRequests),
                        }}
                      >
                        {serviceRow.serviceId}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          ...lastBorderStyle(index, row?.serviceRequests),
                        }}
                      >
                        {serviceRow.serviceOwnerEmail}
                      </TableCell>
                      <TableCell
                        style={{
                          ...lastBorderStyle(index, row?.serviceRequests),
                        }}
                      >
                        <Box display="flex">
                          <Status status={serviceRow.status} />
                          <Box paddingLeft="10px">
                            {serviceRow.canUpdate && (
                              <EditIcon
                                color="action"
                                fontSize="small"
                                onClick={() => {
                                  setSelected([]);
                                  setSelectAllCheck('');
                                  setSelected([serviceRow.id]);
                                  handleEditIconClick(serviceRow);
                                  setSelectAllCheck('intermediate');
                                }}
                                style={{ cursor: 'pointer' }}
                              />
                            )}
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          ...lastBorderStyle(index, row?.serviceRequests),
                        }}
                      >
                        {dateFormatter({
                          date: serviceRow.updatedAt,
                          withTime: true,
                        })}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          maxWidth: '65px',
                          minWidth: '45px',
                          ...lastBorderStyle(index, row?.serviceRequests),
                        }}
                      >
                        {serviceRow.note || '-'}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {selected.length > 1 && (
                <Box>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: HemaBlueDarkColor,
                      textTransform: 'lowercase',
                      fontSize: '12pt',
                      fontWeight: '100',
                      display: 'block',
                      marginLeft: 'auto',
                      marginRight: 0,
                      marginTop: '1Rem',
                      marginBottom: '1Rem',
                    }}
                    onClick={() => handleBulkEditClick(selected)}
                  >
                    bulk edit status
                  </Button>
                </Box>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};
