export const typeFormatter = (type: string) => {
  switch (type) {
    case 'FORGET_ME':
      return 'to be forgotten';
    default:
      return '';
  }
};

export const dateFormatter = ({
  date,
  locale,
  withTime,
}: {
  date: string;
  locale?: string;
  withTime?: boolean;
}): string => {
  try {
    if (!date) {
      return '-';
    }

    const options: Intl.DateTimeFormatOptions = withTime
      ? {
          dateStyle: 'short',
          timeStyle: 'short',
        }
      : {
          dateStyle: 'short',
        };
    return new Intl.DateTimeFormat(locale || 'nl-NL', options).format(new Date(date));
  } catch (error) {
    return '-';
  }
};

export const statusFormatter = (status: string) =>
  status.replace('in progress', 'IN_PROGRESS').replace('completed', 'COMPLETED').replace('declined', 'DECLINED');
