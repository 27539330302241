import { ReactNode } from 'react';
import Alert from '@mui/material/Alert';

const NotificationBoundary = ({
  children,
  errorMessage,
  setErrorMessage,
  successMessage,
  setSuccessMessage,
  infoMessage,
  setInfoMessage,
}: {
  children?: ReactNode;
  errorMessage?: string;
  setErrorMessage: Function;
  successMessage?: string;
  setSuccessMessage: Function;
  infoMessage?: string;
  setInfoMessage: Function;
}) => {
  return (
    <>
      {errorMessage && (
        <Alert severity="error" onClose={() => setErrorMessage('')}>
          {errorMessage}
        </Alert>
      )}
      {successMessage && (
        <Alert severity="success" onClose={() => setSuccessMessage('')}>
          {successMessage}
        </Alert>
      )}
      {infoMessage && (
        <Alert severity="info" onClose={() => setInfoMessage('')}>
          {infoMessage}
        </Alert>
      )}
      {children}
    </>
  );
};

export default NotificationBoundary;
