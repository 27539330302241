import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import Pagination from '@mui/material/Pagination';

import { CustomerRequestResponseItem, ServiceRequest } from '../../services/gdpr.api';
import { Row } from './Row';

export type CollapsibleTableProps = {
  onEditIconClick?: (affectedServiceRow: ServiceRequest) => void;
  onBulkEditClick?: (bulkServiceRowIds: number[]) => void;
  totalPageCount: number;
  paginationNumber: number;
  rows?: CustomerRequestResponseItem[];
  onPaginationChanged: (number: number) => void;
};

export const CollapsibleTable = ({
  rows,
  totalPageCount,
  paginationNumber,
  onEditIconClick,
  onBulkEditClick,
  onPaginationChanged,
}: CollapsibleTableProps) => {
  if (rows?.length === 0) {
    return <Box color="gray">No record found!</Box>;
  }

  return (
    <TableContainer component={Paper} data-testid="collapsible-table">
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell style={{ width: 10 }} />
            <TableCell align="left">request-id</TableCell>
            <TableCell align="left">hema-id</TableCell>
            <TableCell align="left">created</TableCell>
            <TableCell align="left">type</TableCell>
            <TableCell align="left">deadline</TableCell>
            <TableCell align="left">status</TableCell>
          </TableRow>
        </TableHead>
        {rows && (
          <TableBody>
            {rows.map((row, index) => (
              <Row
                key={row.hemaId}
                row={row}
                isOddRow={index % 2 === 0}
                onEditIconClick={onEditIconClick}
                onBulkEditClick={onBulkEditClick}
              />
            ))}
          </TableBody>
        )}
      </Table>

      <Box display="flex" padding="1rem" paddingTop="2.5rem" justifyContent="flex-end">
        <Pagination
          count={totalPageCount}
          variant="outlined"
          shape="rounded"
          page={paginationNumber}
          onChange={(ev, number) => {
            onPaginationChanged(number);
          }}
        />
      </Box>
    </TableContainer>
  );
};
