/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { graphConfig, securityConfig } from '../config/authConfig';

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken
 */

export async function callMsGraph(accessToken: string) {
  const result = await axios.get(graphConfig.graphMeEndpoint, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  return result.data;
}

/**
 * Exchanges azure token for hema auth token
 * @param subjectToken
 */
export async function getAccessToken(subjectToken: string) {
  try {
    const result = await axios.post(
      '/token',
      {
        grant_type: 'urn:ietf:params:oauth:grant-type:token-exchange',
        client_id: securityConfig.clientId,
        client_secret: securityConfig.clientSecret,
        subject_token: subjectToken,
      },
      {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      }
    );
    return result.data;
  } catch (error: any) {
    throw new Error(`Error fetching token: ${error.message}`);
  }
}
