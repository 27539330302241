import styled from '@emotion/styled';
import { Avatar, Box, Divider, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import { useMsal } from '@azure/msal-react';

import { useState } from 'react';
import HemaLogo from '../../assets/svg/hema-logo.svg';

const Container = styled.div`
  display: flex;
  background-color: white;
  padding: 20px;
`;

const Logo = styled.img`
  padding-right: 20px;
`;

const HeaderTitle = styled.h2`
  display: flex;
  align-items: center;
  font-family: HemaBold;
`;

const UserProfile = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 10px;
  font-weight: 600;
`;

const Header = () => {
  const { instance, accounts } = useMsal();
  const account = accounts[0];
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleLogout = () => {
    setAnchorElUser(null);
    instance.logoutPopup({
      postLogoutRedirectUri: '/',
      mainWindowRedirectUri: '/',
    });
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <Container data-testid="header">
      <Logo src={HemaLogo} alt="hema-logo" />
      <HeaderTitle>gdpr compliance management tool</HeaderTitle>
      {account?.name && (
        <UserProfile>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt={account?.name} src="/" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem>
                <Typography textAlign="center">{account.name}</Typography>
              </MenuItem>
              <Divider style={{ padding: 0, margin: 0 }} />
              <MenuItem onClick={handleLogout}>
                <Typography textAlign="center">Log out</Typography>
              </MenuItem>
            </Menu>
          </Box>
          {/* <Box>{account.name}</Box> */}
        </UserProfile>
      )}
    </Container>
  );
};

export default Header;
