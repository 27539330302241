/* eslint-disable @typescript-eslint/no-explicit-any */
import { LogLevel } from '@azure/msal-browser';

export const securityConfig = {
  clientId: process.env.REACT_APP_HEMA_CLIENT_ID,
  clientSecret: process.env.REACT_APP_HEMA_CLIENT_SECRET,
};

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MSAL_CLIENT_ID, // 'f669b0ee-b59c-48ea-942b-16ddb3b98eed'
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_MSAL_AUTHORITY}/`, // 'https://login.microsoftonline.com/2732b983-d158-474a-9b35-2fcef618dd9e/'
    redirectUri: `${process.env.NODE_ENV === 'development' ? 'http' : 'https'}://${
      process.env.REACT_APP_MSAL_REDIRECT_URL
    }`, // 'http://localhost:3000'
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
        }
      },
    },
  },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
  scopes: ['User.Read'],
};

/**
 * Add here the scopes to request when obtaining an access token for MS Graph API. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};
