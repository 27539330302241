import { Box } from '@mui/material';
import MoonLoader from 'react-spinners/MoonLoader';
import { HemaRedColor } from '../../assets/color/hema-colors';

const ProgressSpinner = () => (
  <Box
    data-testid="progress-spinner"
    style={{
      backgroundColor: '#a1a1a124',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      width: '100%',
      height: '100%',
      zIndex: 1000000,
    }}
  >
    <MoonLoader color={HemaRedColor} />
  </Box>
);

export default ProgressSpinner;
