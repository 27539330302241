import { useState } from 'react';
import { FormControl, TextField, Box } from '@mui/material';
import { validateHemaIds } from '../../utils/validators';

const CreateNewRequestForm = ({
  showErrorMessage,
  setHemaIdsForCreation,
  setIsCreateRequestFormValid,
}: {
  showErrorMessage: boolean;
  setHemaIdsForCreation: Function;
  setIsCreateRequestFormValid: Function;
}) => {
  const [notValidHemaId, setNotValidHemaId] = useState('');
  return (
    <Box style={{ display: 'flex', flexDirection: 'column' }}>
      <FormControl>
        <TextField
          error={showErrorMessage}
          id="outlined-multiline-flexible"
          label="Add hema id or multiple hema ids separated with comma"
          helperText={
            showErrorMessage &&
            `Hema id: ${notValidHemaId} is not a valid UUID or the format for multiple values is not correct.`
          }
          multiline
          rows={4}
          onChange={async (event) => {
            const hemaIdArr = event.target.value.split(',').filter((value) => value !== '');
            const { isValid, notValidUuid } = validateHemaIds(hemaIdArr);
            if (notValidUuid) setNotValidHemaId(notValidUuid);
            setIsCreateRequestFormValid(isValid);
            setHemaIdsForCreation(hemaIdArr);
          }}
        />
      </FormControl>
    </Box>
  );
};

export default CreateNewRequestForm;
