import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const Container = styled(Box)(() => ({
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: '1rem',
  marginTop: '1rem',
  marginLeft: '1rem',
  marginRight: '1rem',
  minHeight: '80vh',
  '& .MuiTableCell-head': {
    fontFamily: 'hemaBold',
    fontSize: '16px',
  },
}));

export const HeaderWrapper = styled.div`
  width: 100%;
`;

export const BodyWrapper = styled.div`
  width: 100%;
`;

export const HeaderTitle = styled.h1`
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
  font-family: hemaBold;
`;

export const SubTitle = styled.p`
  align-items: center;
  padding-bottom: 1rem;
`;
