import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '30rem',
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    width: '21',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2, fontFamily: 'HemaBold' }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export const CustomizedDialog = ({
  formIsValid,
  modalTitle,
  actionButtonText,
  onActionClick,
  onModalClose,
  onModalAccept,
  children,
}: {
  formIsValid: boolean;
  modalTitle?: string;
  actionButtonText?: string;
  onActionClick?: () => void;
  onModalClose: () => void;
  onModalAccept: () => void;
  onModalOpen: () => void;
  children?: React.ReactNode;
}) => {
  return (
    <div data-testid="customized-modal">
      <BootstrapDialog onClose={onModalClose} aria-labelledby="customized-dialog-title" open={true}>
        {modalTitle && (
          <BootstrapDialogTitle id="customized-dialog-title" onClose={onModalClose}>
            {modalTitle}
          </BootstrapDialogTitle>
        )}

        <DialogContent dividers>{children}</DialogContent>
        {actionButtonText && (
          <DialogActions>
            <Button autoFocus onClick={onActionClick}>
              {actionButtonText}
            </Button>
          </DialogActions>
        )}

        <DialogContent>
          <Box display="flex" columnGap="1rem">
            <Button
              variant="outlined"
              style={{
                textTransform: 'lowercase',
                fontFamily: 'HemaBold',
              }}
              onClick={onModalAccept}
              disabled={!formIsValid}
            >
              submit
            </Button>
            <Button
              variant="outlined"
              style={{
                textTransform: 'lowercase',
                fontFamily: 'HemaBold',
              }}
              onClick={onModalClose}
            >
              cancel
            </Button>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};
