import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useIsAuthenticated } from '@azure/msal-react';
import { AuthenticationResult, InteractionStatus } from '@azure/msal-browser';
import { ThemeProvider, createTheme } from '@mui/material';

import { callMsGraph, getAccessToken } from './services/securityService';
import CaseSearch from './pages/CaseSearch/CaseSearch';
import { loginRequest } from './config/authConfig';
import ProgressSpinner from './components/ProgressSpinner/ProgressSpinner';
import NotificationBoundary from './components/NotificationBoundary/NotificationBoundary';
import './App.css';
import './assets/fonts/hema-bold.otf';
import './assets/fonts/hema-demibold.otf';
import './assets/fonts/hema-book.otf';

const theme = createTheme({
  typography: {
    fontFamily: `"HemaBook", "HemaBold", "HemaDemiBold"`,
  },
});

export type MSALAccount = {
  displayName: string;
  givenName: string;
  surname: string;
  userPrincipalName: string;
};

const BodyGridWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const CaseSearchWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const App = () => {
  const [loading, toggleLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [infoMessage, setInfoMessage] = useState('');

  // user state
  const [graphData, setGraphData] = useState<MSALAccount>();
  const [accessToken, setAccessToken] = useState('');
  const { instance, accounts, inProgress } = useMsal();
  const account = accounts[0];
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      instance.loginRedirect(loginRequest).catch((e) => {
        throw e;
      });
    } else if (!accessToken && inProgress === InteractionStatus.None) {
      const tokenRequest = {
        ...loginRequest,
        account,
      };

      instance
        .acquireTokenSilent(tokenRequest)
        .then((authenticationResult: AuthenticationResult) => {
          callMsGraph(authenticationResult.accessToken).then((response: MSALAccount) => {
            setGraphData(response);
          });
          getAccessToken(authenticationResult.accessToken).then(async (response: { access_token: string }) => {
            setAccessToken(response.access_token);
          });
        })
        .catch(async (error) => {
          if (error.errorCode === 'interaction_required') {
            instance.logoutRedirect({
              postLogoutRedirectUri: '/',
            });
          }
          throw error;
        });
    }
  }, [isAuthenticated, inProgress, accessToken]);
  return (
    <ThemeProvider theme={theme}>
      <UnauthenticatedTemplate>
        <BodyGridWrapper>You will be redirected to sign in</BodyGridWrapper>
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        {loading && <ProgressSpinner />}
        <NotificationBoundary
          errorMessage={errorMessage}
          successMessage={successMessage}
          setErrorMessage={setErrorMessage}
          setSuccessMessage={setSuccessMessage}
          infoMessage={infoMessage}
          setInfoMessage={setInfoMessage}
        >
          <CaseSearchWrapper>
            <CaseSearch
              toggleLoading={toggleLoading}
              setSuccessMessage={setSuccessMessage}
              graphData={graphData}
              accessToken={accessToken}
              setInfoMessage={setInfoMessage}
              setErrorMessage={setErrorMessage}
            />
          </CaseSearchWrapper>
        </NotificationBoundary>
      </AuthenticatedTemplate>
    </ThemeProvider>
  );
};

export default App;
