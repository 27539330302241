/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemIcon,
  ListItemText,
  Button,
  Tooltip,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import styled from '@emotion/styled';
import { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';

import { HemaBlueDarkColor, HemaWhiteColor } from '../../assets/color/hema-colors';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const Form = styled.form`
  width: 60vw;
  padding: 1rem 1rem 1rem 0;
  display: flex;
  flex-direction: row;
  background-color: white;
`;
const ButtonContainer = styled.div`
  padding: 3px 10px 1px 0;
  display: flex;
  flex-direction: row;
  background-color: white;
`;

const StyledCheckbox = ({ checked }: { checked: boolean }) => {
  return (
    <Checkbox
      checked={checked}
      style={{
        color: HemaBlueDarkColor,
      }}
    />
  );
};

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const FormFilter = ({
  onFilterTextChange,
  onFilterStatusChange,
  toggleCreateRequestsModal,
}: {
  onFilterTextChange: (value: string) => void;
  onFilterStatusChange: (value: string[]) => void;
  toggleCreateRequestsModal: (value: boolean) => void;
}) => {
  const [searchField, setSearchField] = useState('');
  const [statusFilter, setStatusFilter] = useState<string[]>([]);

  const handleFilterChange = async (event: any) => {
    const { value } = event.target;
    setStatusFilter(value);
    onFilterStatusChange(value);
  };

  return (
    <Form data-testid="form-filter">
      <ButtonContainer>
        <Tooltip title="Create new requests">
          <Button
            variant="outlined"
            style={{
              color: HemaBlueDarkColor,
              fill: HemaBlueDarkColor,
            }}
            onClick={() => {
              toggleCreateRequestsModal(true);
            }}
          >
            <AddIcon />
          </Button>
        </Tooltip>
      </ButtonContainer>
      <FormControl fullWidth style={{ paddingRight: 10, marginTop: 3 }}>
        <TextField
          value={searchField}
          type="text"
          placeholder="What are you searching for?"
          variant="outlined"
          onChange={async (event) => {
            onFilterTextChange(event.target.value.trim());
            setSearchField(event.target.value);
          }}
          required
        />
      </FormControl>
      <FormControl fullWidth style={{ marginTop: 2 }}>
        <InputLabel id="filter-label" style={{ backgroundColor: HemaWhiteColor, padding: '0 10px' }}>
          Filter
        </InputLabel>
        <Select
          labelId="multiple-select-label"
          multiple
          value={statusFilter}
          onChange={handleFilterChange}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
          style={{
            border: `0.5px solid rgba(0, 0, 0, 0.1)`,
          }}
        >
          <MenuItem value="in progress">
            <ListItemIcon>
              <StyledCheckbox checked={statusFilter.indexOf('in progress') > -1} />
            </ListItemIcon>
            <ListItemText primary="in progress" />
          </MenuItem>
          <MenuItem value="completed">
            <ListItemIcon>
              <StyledCheckbox checked={statusFilter.indexOf('completed') > -1} />
            </ListItemIcon>
            <ListItemText primary="completed" />
          </MenuItem>
          <MenuItem value="declined">
            <ListItemIcon>
              <StyledCheckbox checked={statusFilter.indexOf('declined') > -1} />
            </ListItemIcon>
            <ListItemText primary="declined" />
          </MenuItem>
        </Select>
      </FormControl>
    </Form>
  );
};

export default FormFilter;
